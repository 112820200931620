import React, { Suspense } from 'react';

import { ApolloProvider } from '@apollo/client';
import styled, { ThemeProvider } from 'styled-components';
import { toast } from 'react-toastify';

import { appTheme, GlobalStyle } from 'styles/theme';
import { client } from 'services/apollo/client';
import AppContext from 'AppContext';
import { Loader, ToastCloseButton } from 'components';
import Router from './Router';

import 'react-toastify/dist/ReactToastify.css';

const StyledLoader = styled(Loader)`
    height: 100vh;
`;

toast.configure({
    hideProgressBar: true,
    toastClassName: 'app-toast',
    className: 'app-toast-container',
    closeButton: <ToastCloseButton />,
});

const App = () => (
    <ThemeProvider theme={appTheme}>
        <AppContext.Component>
            <ApolloProvider client={client}>
                <GlobalStyle />
                <Suspense fallback={<StyledLoader />}>
                    <Router />
                </Suspense>
            </ApolloProvider>
        </AppContext.Component>
    </ThemeProvider>
);

export default App;
