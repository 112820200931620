import { OrderState } from 'types/order';
import { tabStates } from './orderService';

const defaultDisplayedOrderStates = [
    OrderState.ACCEPTED,
    OrderState.PREPARING,
    OrderState.READY,
    OrderState.CANCELLED,
    OrderState.REFUSED,
    OrderState.ABANDONED
];

const searchableOrderStates = [...defaultDisplayedOrderStates, OrderState.COMPLETED];

export function getTodaysRangeQuerySearch(key: string) {
    const dayStart = new Date();
    const dayEnd = new Date();
    dayStart.setHours(0, 0, 0, 0);
    dayEnd.setHours(23, 59, 59, 999);
    return [{ key, operator: '>', value: dayStart.toISOString() }, { key, operator: '<', value: dayEnd.toISOString() }];
}

export function getTodaysStartQuerySearch(key: string) {
    const dayStart = new Date();
    const dayEnd = new Date();
    dayStart.setHours(0, 0, 0, 0);
    dayEnd.setHours(23, 59, 59, 999);
    return [{ key, operator: '>', value: dayStart.toISOString() }];
}

export function getQuerySearch(offerId: string, isSearch: boolean = false, searchValue?: string) {
    const stateValues = isSearch ? searchableOrderStates.join() : defaultDisplayedOrderStates.join();

    const query = [
        { key: 'idOffer', value: offerId },
        { key: 'state', operator: '*', value: stateValues },
        ...getTodaysStartQuerySearch('withdrawRange'),
    ];

    return searchValue ? [{ key: 'search', value: searchValue }, ...query] : query;
}

export function getQuerySearchByTab(offerId: string, tab) {

    return [
        { key: 'idOffer', value: offerId },
        { key: 'state', operator: '*', value: tabStates[tab].join() },
        ...getTodaysStartQuerySearch('withdrawRange'),
    ];
}

export function getNodes<T>({ edges }: { edges: Array<{ node: T }> }): T[] {
    return edges.map(e => e.node);
}
