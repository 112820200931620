import React, { Component } from 'react';
import styled, { withTheme, DefaultTheme } from 'styled-components';
import { Transition } from 'react-spring/renderprops';

import Portal from './Portal';

const ModalWrapper = styled.section`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    z-index: ${({ theme }) => theme.zIndex.modalOverlay};
    &.blocking {
        z-index: ${({ theme }) => theme.zIndex.blockingModalOverlay};
    }
`;

const ModalCard = styled.div`
    height: 33vh;
    width: 33vw;
    color: ${({ theme }) => theme.palette.text.medium};
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: 12px;
    z-index: 1;
    margin: auto;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 60px;
`;

const Background = styled.div`
    position: relative;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.palette.common.black};
`;

interface IProps {
    isOpen: boolean;
    closeModal?: any;
    theme: DefaultTheme;
    children: any;
}

class Modal extends Component<IProps> {
    render() {
        const { children, isOpen, theme } = this.props;
        const pointerEvents = isOpen ? 'all' : 'none';
        return (
            <Portal>
                <Transition
                    items={isOpen}
                    config={{ duration: 250 }}
                    from={{ opacity: 0 }}
                    enter={{ opacity: 1 }}
                    leave={{ opacity: 0 }}
                >
                    {isOpen =>
                        isOpen &&
                        (props => (
                            <ModalWrapper>
                                <Background
                                    style={{
                                        pointerEvents,
                                        backgroundColor: theme.palette.background.darkOpacity,
                                        opacity: props.opacity,
                                    }}
                                />
                                <ModalCard>{children}</ModalCard>
                            </ModalWrapper>
                        ))
                    }
                </Transition>
            </Portal>
        );
    }
}

export default withTheme(Modal);
