import React, { useContext, useEffect } from 'react';
import AppContext from 'AppContext';
import { loader } from 'graphql.macro';
import { IOffer, IOfferWithOrder } from 'types/order';
import DateOfferSelectorComponent from './DateOfferSelectorComponent';
import { useQuery } from '@apollo/client';
import { makeNumericId } from 'services/orderService';
import config from 'config.json';
const countOrders = loader('../../../../../queries/countOrders.graphql');
interface IProps {
    className?: string;
    offersWithOrders: IOffer[];
}

const DayOfferSelector = ({ className, offersWithOrders }: IProps) => {
    const { offerDate, setOfferDate, shouldFetchNewOfferOrders, cleanOrdersAndCounters } = useContext(AppContext.Context);
    // eslint-disable-next-line
    let offersWithOrdersAndCount: IOfferWithOrder[] = [];
    let offerIdList: string[] = offersWithOrders.map((offer) => offer.id);

    const { data, loading } = useQuery(countOrders, {
        variables: {
            idOffers: offerIdList
        },
        skip: !offersWithOrders || offersWithOrders.length === 0,
        pollInterval: config.ORDER_COUNTER_POLLING_INTERVAL,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (offersWithOrdersAndCount.length > 0 && offerDate === undefined) {
            setOfferDate(offersWithOrdersAndCount[0]);
        }
    }, [offersWithOrdersAndCount, offerDate, setOfferDate]);

    const handleOfferDate = (offer: IOffer) => {
        setOfferDate(offer);
        shouldFetchNewOfferOrders(true);
        cleanOrdersAndCounters();
    };

    if (!offersWithOrders || offersWithOrders.length === 0) {
        return null;
    }

    if (loading) {
        return null;
    }

    offersWithOrders.forEach((offer) => {
        data.listOffersWithOrdersCount.forEach((offerWithOrderCount: { id: number; count: number }) => {
            if (makeNumericId(offer.id) === offerWithOrderCount.id) {
                offer = { ...offer, count: offerWithOrderCount.count };
                if (offerDate && offer.id === offerDate.id && offer.count !== offerDate.count) {
                    setOfferDate(offer);
                }
                offersWithOrdersAndCount.push(offer);
            }
        });
    });

    const isFirstOfferForToday =
        offersWithOrdersAndCount.length > 0
            ? new Date(offersWithOrdersAndCount[0].withdrawRange.split('/')[0]).toLocaleDateString() ===
            new Date().toLocaleDateString()
            : false;

    if (
        (offersWithOrdersAndCount.length > 0 && !isFirstOfferForToday) ||
        offersWithOrdersAndCount.length > 1
    ) {
        return (
            <DateOfferSelectorComponent
                className={className}
                selectedOffer={offerDate === undefined ? offersWithOrdersAndCount[0] : offerDate}
                options={offersWithOrdersAndCount}
                handleChange={handleOfferDate}
            />
        );
    }
    return null;
};

export default DayOfferSelector;
