import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getItemsFamily } from 'services/orderService';
import { OrderState, IOrder, LocalOrderState } from 'types/order';
import { ArticleFamily } from 'types/graphql/globalTypes';

const Title = styled.h4`
    padding-bottom: ${({ theme }) => theme.spacing.padding}px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.text.medium};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
`;

const Item = styled.li`
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.padding}px 0;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    color: ${({ theme }) => theme.palette.text.medium};
`;

interface QuantityProps {
    readonly state: OrderState;
    readonly isLate?: boolean;
}

const Quantity = styled.span<QuantityProps>`
    width: 28px;
    height: 28px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: ${({ theme, state, isLate }) =>
        theme.palette.background.order[isLate ? LocalOrderState.LATE : state]};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    color: ${({ theme, state }) => theme.palette.text.order[state]};
`;

const Label = styled.span`
    padding-left: ${({ theme }) => theme.spacing.padding}px;
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    line-height: ${({ theme }) => theme.typography.fontSizeM * 1.2}px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const Section = styled.section`
    margin-bottom: ${({ theme }) => theme.spacing.unit * 4}px;
    .visibleSections & {
        margin: ${({ theme }) => theme.spacing.unit}px;
        padding: ${({ theme }) => theme.spacing.padding}px;
        border-radius: 8px;
        background-color: ${({ theme }) => theme.palette.common.white};
        box-shadow: 0 0 10px -4px rgba(65, 57, 107, 0.2);
    }
    &:last-child {
        margin-bottom: 0;
    }
`;

interface IProps {
    order: IOrder;
    className?: string;
}

const OTHER = 'OTHER';
type DisplayedArticleFamily = ArticleFamily | 'OTHER';

const articleFamilies: DisplayedArticleFamily[] = [
    ...Object.keys(ArticleFamily) as ArticleFamily[],
    OTHER,
];

function Detail({ order: { orderItems, state, isLate }, className, t }: IProps & WithTranslation) {
    const itemsFamily = getItemsFamily(orderItems, OTHER);
    return (
        <div className={className}>
            {articleFamilies.map((type, i) => {
                if (!itemsFamily[type]) return null;
                return (
                    <Section key={`section-${i}`}>
                        <Title>{t(`schema:article.type.${type}`)}</Title>
                        <ul>
                            {itemsFamily[type].map((item, i) => (
                                <Item key={`item-${i}`}>
                                    <Quantity isLate={isLate} state={state}>
                                        {item.quantity}
                                    </Quantity>
                                    <Label>{item.offerItem!.inheritedLabel}</Label>
                                </Item>
                            ))}
                        </ul>
                    </Section>
                );
            })}
        </div>
    );
}

export default withTranslation()(Detail);
