import React from 'react';
import { Select } from 'components';
import { IOffer, IOfferTemplate } from 'types/order';

interface IProps {
    className?: string;
    selectedOffer: IOffer | IOfferTemplate | undefined;
    options: IOffer[] | IOfferTemplate[];
    handleChange: (offer: any) => void;
}

const OfferSelectorComponent = ({ className, selectedOffer, options, handleChange }: IProps) => (
    <div className={className}>
        <Select 
            selectedOption={selectedOffer} 
            options={options} 
            handleChange={handleChange} 
        />
    </div>
);

export default OfferSelectorComponent;
