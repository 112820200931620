import React from 'react';
import { IOffer } from 'types/order';
import OfferSelectorComponent from './OfferSelectorComponent';

interface IProps {
    className?: string;
    formattedOffers: IOffer[];
    currentOffer: IOffer | undefined;
    handleChange: (offer: IOffer) => void;
}

const OfferSelector = ({ className, formattedOffers, currentOffer, handleChange }: IProps) => {

    return formattedOffers.length > 1 ? (
        <OfferSelectorComponent
            className={className}
            selectedOffer={currentOffer}
            options={formattedOffers}
            handleChange={handleChange}
        />
    ) : null;
};

export default OfferSelector;
