import React, { Component } from 'react';
import styled, { withTheme, DefaultTheme } from 'styled-components';
import { Transition } from 'react-spring/renderprops';

import { ReactComponent as LockImg } from 'ressources/img/locked.svg';

import Portal from './Portal';

const ModalWrapper = styled.section`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    z-index: ${({ theme }) => theme.zIndex.modalOverlay};
    &.blocking {
        z-index: ${({ theme }) => theme.zIndex.blockingModalOverlay};
    }
`;

const ModalCard = styled.div`
    height: 100vh;
    width: ${({ theme }) => theme.width.sideModal}px;
    color: ${({ theme }) => theme.palette.text.medium};
    display: flex;
    flex-direction: column;
    background-color: white;
`;

const Background = styled.div`
    position: relative;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.palette.common.black};
`;

const StyledLockImg = styled(LockImg)`
    position: fixed;
    top: 50vh;
    left: calc((100vw - ${({ theme }) => theme.width.sideModal}px) / 2);
`;

interface IProps {
    isOpen: boolean;
    isBlocking?: boolean;
    closeModal?: any;
    theme: DefaultTheme;
    children: any;
}

class Modal extends Component<IProps> {
    onModalToggle = () => {
        const { closeModal, isBlocking } = this.props;
        if (!isBlocking && closeModal) closeModal();
    };
    render() {
        const { children, isOpen, isBlocking, theme } = this.props;
        const pointerEvents = isOpen ? 'all' : 'none';
        return (
            <Portal>
                <Transition
                    items={isOpen}
                    config={{ duration: 250 }}
                    from={{ x: theme.width.sideModal, opacity: 0 }}
                    enter={{ x: 0, opacity: 1 }}
                    leave={{ x: theme.width.sideModal, opacity: 0 }}
                >
                    {isOpen =>
                        isOpen &&
                        (props => (
                            <ModalWrapper className={isBlocking ? 'blocking' : ''}>
                                <Background
                                    style={{
                                        pointerEvents,
                                        backgroundColor: theme.palette.background.darkOpacity,
                                        opacity: props.opacity,
                                    }}
                                    onTouchStart={this.onModalToggle}
                                >
                                    {isBlocking && <StyledLockImg />}
                                </Background>
                                <ModalCard
                                    style={{
                                        transform: `translate3d(${props.x}px, 0, 0)`,
                                    }}
                                >
                                    {children}
                                </ModalCard>
                            </ModalWrapper>
                        ))
                    }
                </Transition>
            </Portal>
        );
    }
}

export default withTheme(Modal);
