import get from 'lodash/get';

import React, { useState, useEffect, useRef, useContext } from 'react';
import styled, { withTheme, DefaultTheme } from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';

import { orderStateUpdates, stateIcons, hasCardStateInfo } from 'services/orderService';
import { getDisplayTime } from 'services/dateService';
import { IOrder, OrderState, LocalOrderState } from 'types/order';
import AppContext from 'AppContext';
import { ReactComponent as PickupPoint } from 'ressources/img/pick-up-point.svg';
import Action from './Action';
import Detail from './Detail';
import OrderStateLabel from './StateLabel';

const Wrapper = styled.div`
    position: relative;
    width: ${({ theme }) => theme.width.orderCard}px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: ${({ theme }) => theme.spacing.margin * 2}px;
    margin-right: ${({ theme }) => theme.spacing.margin}px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.palette.grey[0]};
    &.late {
        opacity: 0.6;
    }
`;

interface HeaderProps {
    readonly state: OrderState;
    readonly isLate?: boolean;
}

const Header = styled.h3<HeaderProps>`
    height: ${({ theme }) => theme.height.cardHeader}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px ${({ theme }) => theme.spacing.padding}px;
    border-radius: 6px 6px 0px 0px;
    background-color: ${({ theme, state, isLate }) =>
        theme.palette.background.order[isLate ? LocalOrderState.LATE : state]};
    font-size: ${({ theme }) => theme.typography.fontSizeXL}px;
    color: ${({ theme, state }) => theme.palette.text.order[state]};
`;

interface DetailsProps {
    readonly isUrgent?: boolean;
    readonly infoElHeight: number;
}

const StyledDetail = styled(Detail)<DetailsProps>`
    max-height: calc(
        100vh -
            ${({
                theme: {
                    height: { header, tabHeader, stateLabel, cardHeader, cardFooter },
                    spacing: { margin, paddingL, unit },
                },
                isUrgent,
                infoElHeight,
            }) =>
                header +
                tabHeader +
                cardHeader +
                infoElHeight +
                cardFooter +
                stateLabel +
                2 * paddingL +
                4 * margin +
                (isUrgent ? stateLabel + unit * 2 : 0)}px
    );
    overflow: auto;
`;

const Slot = styled.div`
    position: absolute;
    top: -40px;
    font-size: ${({ theme }) => theme.typography.fontSizeXL}px;
`;

const StyledAction = styled(Action)`
    position: absolute;
    top: -20px;
    right: -15px;
`;

const StyledOrderStateLabel = styled(OrderStateLabel)`
    margin: ${({ theme }) => theme.spacing.unit}px;
`;

const Footer = styled.span`
    height: ${({ theme }) => theme.height.cardFooter}px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.text.medium};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: ${({ theme }) => theme.spacing.padding}px;
    color: ${({ theme }) => theme.palette.text.medium};
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
    & span {
        margin-top: ${({ theme }) => theme.spacing.unit}px;
    }
    & span:last-child {
        margin-bottom: ${({ theme }) => theme.spacing.unit}px;
    }
`;

const PickupContainer = styled.div`
  margin: 8px;
  display: flex;
  align-items: center;
`;
const PickupIcon = styled.div`
  height: 24px;
`;
const PickupName = styled.div`
    color: ${({ theme }) => theme.palette.background.default};
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    padding-left: 5px;
`;

const Name = styled.span`
    font-size: 1.1em;
`;

const PaymentOnSite = styled.div`
    height: 34px;
    width: calc(100% - 12px);
    border: 2px solid ${({ theme }) => theme.palette.common.red};
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 8px 6px 0px;
`;

const PaymentOnSiteText = styled.p`
    color: ${({ theme }) => theme.palette.common.red};
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
`;

const CurrencySign = styled.div`
    box-sizing: border-box;
    height: 22.21px;
    width: 22.21px;
    border-radius: 11.10500px;
    border: 2.21px solid ${({ theme }) => theme.palette.common.red};
    color: ${({ theme }) => theme.palette.common.red};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    font-size: 16px;
`;

interface IProps {
    order: IOrder;
    onClick?: () => void;
    hideAction?: boolean;
    theme: DefaultTheme;
}

const OrderCard = ({
    order,
    order: {
        updated,
        withdrawRange,
        isLate,
        state,
        idWithdrawal,
        guest: { firstName, lastName },
        pickupPoint
    },
    onClick = () => {},
    hideAction,
    t,
    theme,
}: IProps & WithTranslation) => {
    const infoEl = useRef(null);
    const [infoElHeight, setInfoElHeight] = useState(0);

    useEffect(() => {
        setInfoElHeight(get(infoEl, 'current.clientHeight'));
    }, []);

    const newState = orderStateUpdates[state][0];
    const { searchDisplay } = useContext(AppContext.Context);
    return (
        <Wrapper onClick={onClick} className={isLate ? 'late' : ''}>
            <Slot>
                {state === OrderState.COMPLETED ? t('schema:order.states.COMPLETED') : getDisplayTime(withdrawRange[0])}
            </Slot>
            {!hideAction && newState && (
                <StyledAction
                    order={order}
                    newState={newState}
                    backgroundColor={isLate && theme.palette.background.order.LATE}
                    immediate={searchDisplay && newState !== OrderState.COMPLETED}
                />
            )}
            <Header isLate={isLate} state={state}>
                <span>#{idWithdrawal}</span>
                {stateIcons[state]}
            </Header>
            {order.isUrgent && <StyledOrderStateLabel state={LocalOrderState.URGENT} />}
            {order.paymentMethod === "ON_SITE" && (
                <PaymentOnSite>
                    <CurrencySign>€</CurrencySign>
                    <PaymentOnSiteText>{t('page:home.orderCard.onSite')}</PaymentOnSiteText>
                </PaymentOnSite>
            )}
            {pickupPoint && pickupPoint.name && <PickupContainer>
              <PickupIcon><PickupPoint/></PickupIcon>
              <PickupName>{pickupPoint.name}</PickupName>
            </PickupContainer>}
            <Info ref={infoEl}>
                {(state === OrderState.READY || searchDisplay) && <Name>{`${firstName} ${lastName}`}</Name>}
                {hasCardStateInfo[state] && (
                    <span>
                        {t(`page:home.orderCard.stateInfo.${state}`, {
                            displayTime: getDisplayTime(updated),
                        })}
                    </span>
                )}
            </Info>
            <StyledDetail
                order={order}
                isUrgent={order.isUrgent}
                className="visibleSections"
                infoElHeight={infoElHeight}
            />
            <Footer>{t('page:home.orderCard.footer.action')}</Footer>
        </Wrapper>
    );
};

export default withTranslation()(withTheme(OrderCard));
