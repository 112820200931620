import { DefaultTheme, createGlobalStyle } from 'styled-components';

const color = {
    white: '#ffffff',
    green: '#00bfa5',
    orange: '#ff8000',
    red: '#F13F3D',
    blue: '#2196f3',
    black: '#000',
    purple: '#6E3ACD'
};

const grey = ['#f7f7f7', '#b9b9b9', '#979797', '#5F7081'];

export const appTheme: DefaultTheme = {
    palette: {
        common: {
            ...color,
        },
        grey,
        button: {
            primary: color.blue,
            secondary: grey[3],
            confirm: color.green,
            error: color.red,
        },
        background: {
            light: grey[0],
            lightOpacity: 'rgba(255,255,255,.2)',
            defaultOpacity: 'rgba(255,255,255,.05)',
            darkOpacity: 'rgba(0, 0, 0, 0.76)',
            errorOpacity: 'rgba(241,63,61, 0.6)',
            default: '#1d2c39',
            lightDark: grey[3],
            dark: '#0f1c27',
            order: {
                ACCEPTED: color.blue,
                PREPARING: color.orange,
                READY: color.green,
                COMPLETED: color.white,
                URGENT: color.red,
                LATE: grey[1],
                REFUSED: grey[3],
                CANCELLED: grey[3],
                ABANDONED: grey[3],
            },
            badge: {
                PREPARE: color.blue,
                DISTRIBUTE: color.green,
                DISTRIBUTED: color.purple,
                CANCELLED: grey[3],
            },
            stateUpdate: {
                ACCEPTED: '',
                PREPARING: color.blue,
                READY: color.orange,
                COMPLETED: color.green,
                REFUSED: color.red,
                URGENT: '',
                LATE: '',
                CANCELLED: '',
                ABANDONED: '',
            },
        },
        text: {
            light: color.white,
            medium: grey[3],
            dark: '#353535',
            extraDark: '#1d1d1d',
            error: color.red,
            order: {
                ACCEPTED: color.white,
                PREPARING: color.white,
                READY: color.white,
                COMPLETED: color.green,
                URGENT: color.white,
                LATE: color.white,
                REFUSED: color.white,
                CANCELLED: color.white,
                ABANDONED: color.white,
            },
        },
        opacity:{
            _10: '1A',
        },
    },
    typography: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeightExtraLight: 300,
        fontWeightLight: 400,
        fontWeightRegular: 600,
        fontWeightMedium: 700,
        fontWeightBold: 800,
        fontSizeXS: 16,
        fontSizeS: 19,
        fontSizeM: 21,
        fontSizeL: 26,
        fontSizeXL: 30,
        fontSizeXXL: 60,
        size: {
            S: '19',
            M: '21',
            L: '26',
            XL: '30',
            XXL: '60',
        },
        lineHeight: {
            S: '19',
            M: '21',
            L: '33',
            XL: '41',
            XXL: '60',
        },
    },
    spacing: {
        margin: 20,
        marginL: 30,
        padding: 16,
        paddingL: 30,
        unit: 8,
    },
    width: {
        sideModal: 865,
        orderCard: 300,
        select: 365,
        searchInput: 480,
        stateLabel: 220,
        toast: 575,
    },
    height: {
        header: 140,
        tabHeader: 85,
        select: 70,
        searchInput: 70,
        stateLabel: 40,
        cardHeader: 80,
        cardFooter: 52,
        cardPlaceholder: 640,
        checkIcon: 110,
        toast: 140,
    },
    zIndex: {
        actionButton: 10,
        modalOverlay: 30,
        idleOverlay: 31,
        blockingModalOverlay: 32,
        headerEssentials: 40,
    },
};

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    /* removes normally 300ms tap delay on mobile - https://developers.google.com/web/updates/2013/12/300ms-tap-delay-gone-away */
    touch-action: manipulation;
    /* disables pull to refresh in modern browsers */
    overscroll-behavior: contain;
    background-color: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.text.light};
    font-family: "Open Sans", sans-serif;
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    user-select: none;
    overflow: hidden;
  }

  .app-toast-container {
    top: ${({ theme }) => theme.height.header + theme.spacing.paddingL}px !important;
    right: ${({ theme }) => theme.spacing.paddingL}px !important;
    width: ${({ theme }) => theme.width.toast}px !important;
    padding: 0px !important;
  }

  .app-toast {
    width: ${({ theme }) => theme.width.toast}px !important;
    height: ${({ theme }) => theme.height.toast}px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px !important;
    background-color: rgba(44,68,85, 0.9) !important;
    border-left: 13px solid ${({ theme }) => theme.palette.common.red};
    color: ${({ theme }) => theme.palette.text.light} !important;
  }

  button {
    border: none;
  }

  button:active,
  button:focus {
    border-style: none;
    outline: none;
  }
`;
