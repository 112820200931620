import React from 'react';
import { Select } from 'components';
import { IOfferWithOrder } from 'types/order';

interface IProps {
    className?: string;
    selectedOffer: IOfferWithOrder | undefined ;
    options: IOfferWithOrder[];
    handleChange: (offer: any) => void;
}

const DateOfferSelectorComponent = ({ className, selectedOffer, options, handleChange }: IProps) => (
    <div className={className}>
        <Select 
            selectedOption={selectedOffer}
            options={options}
            customLabel={true}
            handleChange={handleChange}
        />
    </div>
);

export default DateOfferSelectorComponent;
