import get from 'lodash/get';
import { OfferTemplateWithdrawalType } from 'types/graphql/globalTypes';

import { listOffers_offers, listOffers_offers_edges_node_OfferTemplate } from 'types/graphql/listOffers';
import { IOffer } from 'types/order';

// CAT offerTemplates have been removed
export function getAvailableOffers(offers: listOffers_offers): IOffer[] {
    return (get(offers, 'edges') || [])
        .map((edge) => edge.node as listOffers_offers_edges_node_OfferTemplate)
        .filter(
            (template) =>
                template.offers &&
                template.offers.length > 0 &&
                template.withdrawalType !== OfferTemplateWithdrawalType.TABLE_SERVICE
        )
        .map((template) => {
            return {
                id: template.offers[0].id,
                value: template.offers[0].id,
                label: template.name,
                secondaryLabel: `${new Date(template.offers[0].withdrawRange.split('/')[0]).toLocaleDateString()}`,
                offerTemplateId: template.id,
                withdrawalType: template.withdrawalType,
                isInAdvance: template.daysInAdvanceEnd > 0,
                withdrawRange: template.offers[0].withdrawRange,
                offerTemplateOffers: template.offers,
            };
        });
}

export function getAvailableOffersWithOrders(currentOffer: IOffer): IOffer[] {
    if (currentOffer.offerTemplateOffers) {
        return currentOffer.offerTemplateOffers
            .sort((a: IOffer, b: IOffer) => {
                return (
                    new Date(a.withdrawRange.split('/')[0]).valueOf() -
                    new Date(b.withdrawRange.split('/')[0]).valueOf()
                );
            })
            .map((offer: IOffer) => {
                return {
                    id: offer.id,
                    value: offer.id,
                    label: new Date(offer.withdrawRange.split('/')[0]).toLocaleDateString(),
                    secondaryLabel: `${new Date(offer.withdrawRange.split('/')[0]).toLocaleDateString()}`,
                    offerTemplateId: offer.offerTemplateId,
                    isInAdvance: currentOffer.isInAdvance,
                    withdrawalType: offer.withdrawalType,
                    withdrawRange: offer.withdrawRange,
                };
            });
    }
    return [];
}
