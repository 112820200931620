import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactComponent as PrinterImg } from 'ressources/img/print.svg';
import { Header, Wrapper, Jumbo, PrinterImgIcon, Title, Text, PrinterInfo, Close } from './styles';
import { Button } from 'components/Button';
import styled from 'styled-components';
import { getItem, LocalStorageKey, setItem } from '../../services/localStorageService';
import { Select } from 'components';

const DEFAULT_TICKETS = '1';

interface IPrinterInfoContentProps {
    closeModal: Function;
    editPrinter: Function;
    isOpen: boolean;
    name: string;
    ip: string;
}

const PrinterInfoContent = withTranslation()(
    ({ closeModal, editPrinter, isOpen, name, ip, t }: IPrinterInfoContentProps & WithTranslation) => {
        const storedNumberOfTickets = getItem(LocalStorageKey.TICKETS_NUMBER);
        const [numberOfTickets, setNumberOfTickets] = useState(storedNumberOfTickets);

        if (!storedNumberOfTickets) {
            setNumberOfTickets(DEFAULT_TICKETS);
            setItem(LocalStorageKey.TICKETS_NUMBER, DEFAULT_TICKETS);
        }

        const copiesOptions = Array.from({ length: 10 }, (_, i) => {
            return {
                value: String(i + 1),
                label: String(i + 1),
            };
        });

        const selectedOption = { value: numberOfTickets, label: numberOfTickets };

        const handleTicketsNumber = (newState: string) => {
            const numTickets = newState.replace(/\D/g, '');
            setNumberOfTickets(numTickets);
            setItem(LocalStorageKey.TICKETS_NUMBER, numTickets);
        };

        return (
            <>
                <Header>
                    <span>{t('page:home.printerModal.found.header')}</span>
                    <Close onClick={() => closeModal(!isOpen)}>&times;</Close>
                </Header>
                <Wrapper>
                    <Jumbo>
                        <PrinterImgIcon>
                            <PrinterImg />
                        </PrinterImgIcon>
                        <PrinterInfo>
                            <Title>{name}</Title>
                            <Text>{ip}</Text>
                        </PrinterInfo>
                        <Button color="primary" onClick={() => editPrinter()}>
                            <Text color="light">{t('page:home.printerModal.found.edit')}</Text>
                        </Button>
                    </Jumbo>
                    <Row>
                        <TextCopies>{t('page:home.printerModal.copies')}</TextCopies>
                        <Select
                            selectedOption={selectedOption}
                            options={copiesOptions}
                            handleChange={(selectedOption: any) => handleTicketsNumber(selectedOption.value)}
                            style={{ width: 105 }}
                        />
                    </Row>
                </Wrapper>
            </>
        );
    }
);

interface IBloc {
    size: number;
    direction: 'column' | 'row';
    justify?: string;
    align?: string;
    wrapped?: boolean;
    auto?: boolean;
}

const Bloc = styled.div<IBloc>`
    max-width: calc(((${({ size = '12' }) => size}% / 12) * 10e7) / 10e5);
    flex-grow: 0;
    justify-content: ${({ justify = 'flex-start' }) => justify};
    align-items: ${({ align = 'center' }) => align};
    display: flex;
    width: ${({ auto = false }) => (auto ? 'auto' : '100%')};
    flex-direction: ${({ direction = 'row' }) => direction};
    flex-wrap: ${({ wrapped = false }) => (wrapped ? 'wrap' : 'nowrap')};
`;

const TextCopies = styled.div`
    color: ${({ theme }) => theme.palette.grey[3]};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    margin-right: ${({ theme }) => theme.spacing.margin}px;
`;

export const Row = (props: any) => <Bloc {...props} />;

export default PrinterInfoContent;
