import React, { useState, useContext, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import AppContext from 'AppContext';
import styled from 'styled-components';
import { ReactComponent as PrinterKOImg } from 'ressources/img/printer-ko.svg';
import Modal from 'components/Modal';
import { Loader as Spinner } from 'components';
import PrinterService, { EpsonPrinter, Printer, IPrinter } from 'services/printService';
import { LocalStorageKey, getItem, setItem, removeItem } from 'services/localStorageService';
import {
    Text,
    Header,
    Title,
    Close,
    PrinterImgIcon,
    PrinterListItemWrapper,
    Wrapper,
    Jumbo,
    FormWrapper,
    InputWrapper,
    Label,
    Input,
    PrinterListWrapper,
} from './styles';
import { Button } from 'components/Button';
import PrinterInfoContent from './PrinterInfoContent';

import TicketHeader from 'ressources/img/ticket-header.svg';
import TicketFooter from 'ressources/img/ticket-footer.svg';

const createContextImage = async (imageSrc: string): Promise<CanvasRenderingContext2D> => {
    return new Promise<CanvasRenderingContext2D>((resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (ctx) {
                canvas.width = image.naturalWidth;
                canvas.height = image.naturalHeight;
                ctx.drawImage(image, 0, 0, image.width, image.height);
                resolve(ctx);
            } else {
                reject(new Error('not supported'));
            }
        };
        image.onerror = reject;
    });
};

const Loader = styled(Spinner)`
    height: 30px;
    width: 30px;
    display: flex;
`;

const checkPrinter = async (ipValue: string): Promise<Printer[] | null> => {
    const printer = await createPrinter(ipValue);
    if (printer) {
        PrinterService.printer = printer;
        if (await PrinterService.printer.isOnline()) {
            return [PrinterService.printer];
        }
    }
    return null;
};
const createPrinter = async (ip: string) => {
    if (ip) {
        const printer = new EpsonPrinter(ip);
        printer.header = await createContextImage(TicketHeader);
        printer.footer = await createContextImage(TicketFooter);
        PrinterService.printer = printer;
        return printer;
    }
};
const printTestPage = (printer: Printer) =>
    printer.print(
        {
            id: '',
            __typename: 'Order',
            idWithdrawal: 1,
            guest: {
                firstName: 'Test',
                lastName: 'Impression',
                __typename: 'Guest',
            },
            withdrawRange: [new Date(), new Date()],
            updated: Date.now(),
            orderItems: [],
            totalPrice: {
                amount: 0,
            },
            state: 'ACCEPTED',
        } as any,
        {
            label: 'Offer test',
        } as any
    );
interface IPrinterListItemProps {
    printer: Printer;
}
const PrinterListItem = ({ printer: { info } }: IPrinterListItemProps) => (
    <>
        <Text bold>{info.name}</Text>
        <Text>{`${info.ip}:${info.port}`}</Text>
    </>
);

enum PRINTER_ERROR {
    NOT_FOUND = 'page:home.printerModal.notFound.form.notFound',
}

interface IProps {
    isOpen: boolean;
    closeModal: Function;
}

const PrinterModal = ({ isOpen, t, closeModal }: IProps & WithTranslation) => {
    const [ipValue, setIpValue] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [hasError, setError] = useState('');
    const [printers, setPrinterList] = useState<Printer[]>([]);
    const {
        printer: { setPrinter: setAppPrinter, selected: selectedAppPrinter },
    } = useContext(AppContext.Context);

    useEffect(() => {
        const storedPrinter = getItem<IPrinter>(LocalStorageKey.PRINTER);
        if (storedPrinter && storedPrinter.ip) {
            setIpValue(storedPrinter.ip);
        }
    }, [ipValue]);

    useEffect(() => {
        const setDefaultPrinter = async () => {
            if (!selectedAppPrinter) {
                const storedPrinter = getItem<IPrinter>(LocalStorageKey.PRINTER);
                if (storedPrinter) {
                    const ip = `${storedPrinter.ip}:${storedPrinter.port}`;
                    const printer: Printer = (await createPrinter(ip)) as Printer;
                    setAppPrinter(printer);
                    printTestPage(printer);
                }
            }
        };
        setDefaultPrinter();
    }, [selectedAppPrinter, setAppPrinter]);

    const findPrinter = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        const printer = await checkPrinter(ipValue);
        if (printer && printer.length > 0) setPrinterList(printer);
        else setError(PRINTER_ERROR.NOT_FOUND);
        setLoading(false);
    };

    const savePrinter = (printer: Printer) => {
        setItem(LocalStorageKey.PRINTER, printer.info);
        printTestPage(printer);
        setAppPrinter(printer);
    };

    const deletePrinter = () => {
        removeItem(LocalStorageKey.PRINTER);
        setAppPrinter();
    };

    return (
        <Modal isOpen={isOpen} closeModal={closeModal}>
            {!selectedAppPrinter && (
                <>
                    <Header>
                        <span>{t('page:home.printerModal.notFound.header')}</span>
                        <Close onClick={() => closeModal(!isOpen)}>&times;</Close>
                    </Header>
                    <Wrapper>
                        <Jumbo>
                            <PrinterImgIcon>
                                <PrinterKOImg />
                            </PrinterImgIcon>
                            <Title>{t('page:home.printerModal.notFound.title')}</Title>
                        </Jumbo>
                        <Text>{t('page:home.printerModal.notFound.legend')}</Text>
                        {!!printers.length && (
                            <PrinterListWrapper>
                                <Text>{t('page:home.printerModal.notFound.searchResult')}</Text>
                                {printers.map((item) => (
                                    <PrinterListItemWrapper key={item.displayName}>
                                        <PrinterListItem printer={item} />
                                        <Button onClick={() => savePrinter(item)}>
                                            <Text color="light">
                                                {t('page:home.printerModal.notFound.form.addPrinter')}
                                            </Text>
                                        </Button>
                                    </PrinterListItemWrapper>
                                ))}
                            </PrinterListWrapper>
                        )}
                        <FormWrapper onSubmit={findPrinter}>
                            <InputWrapper>
                                <Label>{t('page:home.printerModal.notFound.form.label')}</Label>
                                <Input
                                    name="printerIp"
                                    value={ipValue}
                                    onChange={(e) => setIpValue(e.currentTarget.value)}
                                />
                                {!!hasError && (
                                    <Text style={{ position: 'absolute', bottom: -24 }} color="error">
                                        {t(hasError)}
                                    </Text>
                                )}
                            </InputWrapper>
                            <Button style={{ marginTop: 30 }} color="secondary" type="submit" disabled={isLoading}>
                                <Text color="light">{t('page:home.printerModal.notFound.form.button')}</Text>
                                {isLoading && <Loader />}
                            </Button>
                        </FormWrapper>
                    </Wrapper>
                </>
            )}
            {selectedAppPrinter && (
                <PrinterInfoContent
                    {...{ closeModal, isOpen, editPrinter: deletePrinter, ...selectedAppPrinter.info }}
                />
            )}
        </Modal>
    );
};

export default withTranslation()(PrinterModal);
