import get from 'lodash/get';

import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import config from 'config.json';
import { QuerySearchInput } from 'types/graphql/globalTypes';
import { listOrders } from 'types/graphql/listOrders';
import { getQuerySearch, getQuerySearchByTab } from 'services/queryService';
import AppContext from 'AppContext';
import PageContent, { Component } from './PageContent';
import { ordersStatesCounters } from '../../types/graphql/ordersStatesCounters';
import { TabKey } from '../../types/tab';
import { getFormattedOrders, getOrderCount, tabCounterKey, tabStates } from '../../services/orderService';
import { getSetItem, LocalStorageKey, setItem } from '../../services/localStorageService';
import { OrderState } from '../../types/order';

const listOrdersQuery = loader('../../queries/listOrders.graphql');
const ordersStatesCountersQuery = loader('../../queries/ordersStatesCounters.graphql');

const getSearchQuerySearch = (offerId: string, searchDisplay: boolean, searchValue: string): QuerySearchInput[] => {
    let searchQuerySearch = [] as QuerySearchInput[];
    if (searchDisplay) {
        searchQuerySearch =
            searchValue.length >= 3 ? getQuerySearch(offerId, true, searchValue) : getQuerySearch(offerId, true);
    }
    return searchQuerySearch;
};

const playNotification = () => {
    const audio = new Audio(`${process.env.PUBLIC_URL}beep.mp3`);
    audio.loop = false;
    audio.play().catch(e => console.log(e));
};

const onCompleted = (data: listOrders, setOrders, currentTab, fetchingNextOrders, setFetchingNextOrders, orders) => {
    if (!data || !data.orders) {
        return;
    }

    const formattedOrders = getFormattedOrders(data.orders);

    if(fetchingNextOrders) {
        const ordersUpdated = orders[currentTab];

        ordersUpdated.push(formattedOrders);
        setOrders(currentTab, ordersUpdated.flat());
        setFetchingNextOrders(false);
        return;
    }
    setOrders(currentTab, formattedOrders);


    const persistedOrderIds = getSetItem(LocalStorageKey.ORDER_IDS);
    const newOrderList = formattedOrders.filter(({ id, state }) => {
        if (state === OrderState.ACCEPTED && !persistedOrderIds.has(id)) {
            persistedOrderIds.add(id);
            return true;
        }
        return false;
    });

    if (newOrderList.length) {
        setItem(LocalStorageKey.ORDER_IDS, persistedOrderIds);
        playNotification();
    }
};

const onCountersQueryCompleted = (data: ordersStatesCounters, counters, setCounters, refetchOrders, currentTab,
                                  setRefetchOrders, offerDate, offer, setRefetchLoading, orders,
                                  shouldFetchNewOfferOrders, fetchNewOfferOrders) => {
    if (!data || !data.ordersStatesCounters) {
        return;
    }

    setRefetchOrders(refetchOrders);

    if(counters[currentTab] === 0 && data.ordersStatesCounters[tabCounterKey[currentTab]] !== counters[currentTab]){
      setRefetchLoading(true);
    }

    if(currentTab != null && (
        fetchNewOfferOrders ||
        data.ordersStatesCounters[tabCounterKey[currentTab]] !== counters[currentTab] || (orders[currentTab].length < 100 && data.ordersStatesCounters[tabCounterKey[currentTab]] >= 100))
    ) {
        const offerId = offerDate ? get(offerDate, 'id', '') : get(offer, 'value', '');

        const querySearch = getQuerySearch(offerId);
        const statesQuery = querySearch.find((item) => item.key === 'state');
        statesQuery.value = tabStates[currentTab].join();

        refetchOrders({
            defaultQuerySearch: querySearch
        });

        shouldFetchNewOfferOrders(false);
    }

    setCounters({
        [TabKey.PREPARE]: data.ordersStatesCounters[tabCounterKey.PREPARE],
        [TabKey.DISTRIBUTE]: data.ordersStatesCounters[tabCounterKey.DISTRIBUTE],
        [TabKey.DISTRIBUTED]: data.ordersStatesCounters[tabCounterKey.DISTRIBUTED],
        [TabKey.CANCELLED]: data.ordersStatesCounters[tabCounterKey.CANCELLED],
    });
};

const Home = () => {
    const {
        offer,
        offerDate,
        searchValue,
        searchDisplay,
        home: { currentTab }, setRefetchOrders,
        counters, setCounters, setOrders, fetchingNextOrders, setFetchingNextOrders, orders,
        isOrdersLoading, setIsOrdersLoading,
        shouldFetchNewOfferOrders, fetchNewOfferOrders
    } = useContext(AppContext.Context);
    const offerId = offerDate ? get(offerDate, 'id', '') : get(offer, 'value', '');

    const { loading, error, data, networkStatus, refetch } = useQuery(listOrdersQuery, {
        variables: {
            defaultQuerySearch: getQuerySearchByTab(offerId, currentTab),
            searchQuerySearch: getSearchQuerySearch(offerId, searchDisplay, searchValue),
            withSearch: searchDisplay,
        },
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        onCompleted: (data) => {
            onCompleted(data, setOrders, currentTab, fetchingNextOrders, setFetchingNextOrders, orders);
        },
        skip: !offerId || !!counters,
        notifyOnNetworkStatusChange: true,
        context: {
            debounceKey: 'getOrders'
        }
    });


    useQuery(ordersStatesCountersQuery, {
        variables: {
            querySearch: getQuerySearch(offerId)
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        pollInterval: config.ORDER_COUNTER_POLLING_INTERVAL,
        onCompleted: (data) => { onCountersQueryCompleted(
            data, counters, setCounters, refetch, currentTab, setRefetchOrders, offerDate, offer, setIsOrdersLoading, orders,
            shouldFetchNewOfferOrders, fetchNewOfferOrders
        ) },
        skip: !offerId
    });

    if (!offerId || !data) {
        return <Component orderCount={getOrderCount(counters)} orders={[]} tabsCounters={counters} isLoading={isOrdersLoading}/>;
    }

    return <PageContent loading={loading} error={error} data={data} networkStatus={networkStatus}
                        tabsCounters={counters}
    />;
};

export default Home;
