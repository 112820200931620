export enum LocalStorageKey {
    ACCESS_TOKEN = 'accessToken',
    PRINTER = 'foodi/cnc/',
    OFFER_ID = 'offerId',
    OFFER_TEMPLATE_ID = 'offerTemplateId',
    ORDER_IDS = 'orderIds',
    TICKETS_NUMBER = 'ticketsNumber',
}

export function getItem<T>(key: LocalStorageKey): T {
    const value = localStorage.getItem(key) as unknown;
    try {
        return (typeof value === 'string' ? JSON.parse(value) : value) as T;
    } catch (error) {
        return value as T;
    }
}

export function getSetItem(key: LocalStorageKey): Set<string> {
    const value = localStorage.getItem(key);
    return value ? new Set(JSON.parse(value)) : new Set();
}

export function setItem(key: LocalStorageKey, value: Set<string> | Object | string): void {
    const stringifiedValue = JSON.stringify(value instanceof Set ? [...value] : value);
    localStorage.setItem(key, stringifiedValue);
}

export function removeItem(key: LocalStorageKey) {
    localStorage.removeItem(key);
}
