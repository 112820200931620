import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { Loader, PINModalTrigger, TouchDetector } from 'components';
import Auth from 'services/auth0';
import CallBack from 'components/Auth/CallBack';
import Login from 'components/Auth/Login';
import Home from 'pages/Home';

const StyledLoader = styled(Loader)`
    margin-top: ${({ theme }) => theme.height.header}px;
    height: calc(100vh - ${({ theme }) => theme.height.header}px);
`;

const PrivateRoute = ({
    component: Component,
    pathname,
    ...rest
}: {
    component: React.ComponentType;
    pathname: string;
} & RouteProps) => (
    <Route
        {...rest}
        render={(props: any) =>
            Auth.isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname,
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const MainLayout = () => (
    // @todo: fix scan page
    <Switch>
        <Route component={Home} exact path="/" />
        {/* <Route component={Scan} exact path="/scan" /> */}
    </Switch>
);

class RouterComponent extends Component<{}, { loading: boolean }> {
    state = { loading: true };
    async componentDidMount() {
        try {
            this.setState({ loading: true });
            await Auth.silentAuth();
        } catch (err) {
            if (err.error !== 'login_required') console.log(err.error);
        }
        this.setState({ loading: false });
    }
    render() {
        return (
            <Router>
                <PINModalTrigger />
                <TouchDetector>
                    {!this.state.loading ? (
                        <Switch>
                            <Route path="/login" component={Login} />
                            <Route path="/callback" component={CallBack} />
                            <PrivateRoute pathname="/login" path="/" component={MainLayout} />
                        </Switch>
                    ) : (
                        <StyledLoader />
                    )}
                </TouchDetector>
            </Router>
        );
    }
}

export default RouterComponent;
